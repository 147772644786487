import React, {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import Markdown from 'markdown-to-jsx';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from '../logo.jpg'; // Assurez-vous que le chemin vers l'image est correct
import markdownit from 'markdown-it';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfElementsES from './pdfElements/pdfElementsES'
import '../css/Plan.css'





// Convertit une image en Data URL
const convertImageToBase64 = (url) => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
};

const PlanTesterES = ({text, items, tricks}) => {
    Promise.all([
        convertImageToBase64('/static/media/logo.jpg'),
        convertImageToBase64('/static/media/temporalite.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page1.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page2.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page3.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page4.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page5.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page6.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page7.jpg'),
        convertImageToBase64('/static/media/pdf_ES_page8.jpg'),




    ]).then(([base64Image, base64Image2,base64Image3,base64Image4,base64Image5,base64Image6, base64Image7,base64Image8,base64Image9,base64Image10]) => {


        const header = [
            [
                {
                    text: pdfElementsES.title1,
                    fontSize: 12,
                    alignment: 'center'
                },
                {
                    image: base64Image, // Utilisez l'image comme Data URL
                    fit: [35, 35],
                    alignment: 'center',
                    rowSpan: 2, // Fusionne avec les deux lignes suivantes
                },
            ],
            [
                {
                    text: pdfElementsES.firstSentence,
                    fontSize: 8,
                    alignment: 'center'
                }, ''],

        ];
        const md = new markdownit();
        const htmlText = md.render(text[0]);
        console.log("Markdown converted to HTML:", htmlText);

        const pdfContent = htmlToPdfmake(htmlText);
        console.log("HTML converted to PDF content:", pdfContent);

        const backgroundColors = ['#a8cef0', '#a8f0d4', '#a8b3f0', '#e7a8f0', '#f0d7a8', '#f0a8b9', '#a8aff0', '#c6f0a8'];

        const trickContents = tricks.map((trick, index) => {
            const trickHtmlText = md.render(trick.finalText);
            console.log('Item HTML Text:', trickHtmlText);

            const backgroundColor = backgroundColors[index % backgroundColors.length];
            const trickPdfContent = htmlToPdfmake(trickHtmlText);
            console.log('Item PDF Content:', trickPdfContent);

            // Créer un conteneur avec la couleur de fond
            return {
                table: {
                    body: [
                        [{text: htmlToPdfmake(trickHtmlText), fillColor: backgroundColor, margin: [10, 15, 10, 15]}]
                    ]
                },
                layout: 'noBorders' // Supprimer les bordures de la table
            };
        });

        const cardContents = items.map((item, index) => {
            const itemHtmlText = md.render(item.finalText);
            console.log('Item HTML Text:', itemHtmlText);

            const backgroundColor = backgroundColors[index % backgroundColors.length];
            const itemPdfContent = htmlToPdfmake(itemHtmlText);
            console.log('Item PDF Content:', itemPdfContent);
            /*
                        // Créer un conteneur pour chaque item
                        return {
                            table: {
                                widths: ['*'],
                                body: [
                                    [
                                        {
                                            stack: [
                                                {
                                                    canvas: [
                                                        {
                                                            type: 'rect',
                                                            x: 0,
                                                            y: 0,
                                                            w: 100, // Largeur du rectangle (ajustez selon vos besoins)
                                                            h: 40, // Hauteur du rectangle (ajustez selon vos besoins)
                                                            r: 10, // Rayon des coins pour simuler les coins arrondis
                                                            fillColor: backgroundColor // Couleur de remplissage du rectangle
                                                        }
                                                    ]
                                                },
                                                {
                                                    text: htmlToPdfmake(itemHtmlText),
                                                    layout: 'noBorders',
                                                    margin: [10, 10, 10, 10] // Marge intérieure pour le contenu (ajustez selon vos besoins)
                                                }
                                            ]
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders' // Supprimer les bordures de la cellule du tableau
                        };*/
            // Créer un conteneur avec la couleur de fond
            return {
                table: {
                    body: [
                        [{text: htmlToPdfmake(itemHtmlText), fillColor: backgroundColor, margin: [10, 15, 10, 15]}]
                    ]
                },
                layout: 'noBorders' // Supprimer les bordures de la table
            };
        });


// Définition du document PDF
        const docDefinition = {


                pageMargins: [40, 60, 40, 0], // Marges pour toutes les pages, ajustez selon vos besoins
                content: [
                    {
                        text: pdfElementsES.title1, // This will insert the title text before the image on the first page
                        style: {
                            fontSize: 40,

                            alignment: 'center'
                        },
                    },
                    {
                        image: base64Image, // Utilisez l'image comme Data URL
                        fit: [400, 400],
                        alignment: 'center',
                        margin: [0, 50, 0, 50]
                    },
                    {
                        text: pdfElementsES.firstSentence,
                        fontSize: 14,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    // Exclure l'en-tête de la première page



                    {
                        text: 'Progreso de tu sesión :',
                        fontSize: 26,
                        alignment: 'center',
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },


                    ...pdfContent, // Cela inclura le contenu de pdfContent sous le tableau 'toto'

                    {
                        text: '', pageBreak: 'before'
                    },


                    //contenu de la/les pages conseils

                    {
                        text: pdfElementsES.conseilTitle,
                        fontSize: 26,
                        bold: true,
                        margin: [0, 0, 0, 10],


                    },
                    {
                        text: pdfElementsES.conseilSentence,
                        fontSize: 12
                    },
                    ...cardContents,
                    {text: '', pageBreak: 'after'},
                    //page des astuces
                    {
                        text: pdfElementsES.astucesTitle,
                        fontSize: 26,
                        bold: true,
                        margin: [0, 0, 0, 10],


                    },
                    {
                        text: pdfElementsES.astucesSentence,
                        fontSize: 12
                    },
                    ...trickContents,
                    {text: '', pageBreak: 'after'},
                    //page commune 1
                    ,
                    {
                        image: base64Image3, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image4, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image5, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image6, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image7, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image8, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image9, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                    {
                        image: base64Image10, // Utilisez l'image comme Data URL
                        width: 540,
                        alignment: 'center',
                        pageBreak: 'after'
                    },
                ],
                header: function (currentPage, pageCount, pageSize) {
                    // Afficher l'en-tête à partir de la deuxième page
                    if (currentPage > 1) {
                        return [
                            {
                                table: {
                                    headerRows: 2,
                                    widths: ['93%', '*'],
                                    body: header,
                                },
                                layout: 'lightHorizontalLines',
                                margin: [40, 20, 40, 0], // Marges pour l'en-tête
                            },
                        ];
                    }
                }
                ,
                defaultStyle: {
                    font: 'Roboto'
                }
                ,
            }
        ;

        console.log("Document definition:", docDefinition);

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('plan.pdf');
    }).catch(error => {
        console.error("Error occurred:", error);
    });
}

export default PlanTesterES;