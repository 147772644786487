import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown'
import QuestionAnswerComponent from "./QuestionAnswerComponent";
import Loader from './Loader';
import exchange from "./objects/exchange";
import exchanges from "./objects/exchanges";
import Plan from "./Plan";
import PlanTesterFR from "./PlanTesterFR";
import {ToastContainer, toast} from 'react-toastify';
import ToastStripeOK from "./ToastStripeOK";
import ToastPlan from "./ToastPlan";
import PlanTesterES from "./PlanTesterES";

import PlanTesterEN from "./PlanTesterEN";
import ToastLoadingPlan from "./ToastLoadingPlan";

const ReponseComponent = ({userSessionId, answer, thread, addNewTrick, tricks, addNewItem, locale, items}) => {

    const messages = {
        en: {
            PlanTitle: 'Your personalized plan'
        },
        fr: {
            PlanTitle: 'Votre plan personnalisé'
        },
        es: {
            PlanTitle: 'Tu plan personalizado'
        }
    };

    const [userAnswers, setUserAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answerText, setAnswerText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState(false);
    const [retryAnwers, setRetryAnswers] = useState("");
    const newExchange = () => {
        const newExchange = {...exchange}; // Créez un nouvel objet en copiant les valeurs de l'objet précédent
// Maintenant, vous pouvez réinitialiser les attributs de newExchange tout en conservant les valeurs précédentes
        newExchange.questions = [];
        newExchange.questionsDoc = [];
        newExchange.conseils = [];
        newExchange.encouragement = [];
        newExchange.texte = '';
        return newExchange;
    }
    const emitUserResponse = async (threadId, userAnswers) => {
        setIsLoading(true);
        const content = userAnswers.join(" - ");
        console.log('content:', content);

        // Fonction pour gérer le timeout
        const fetchWithTimeout = (url, options, timeout = 300000) => { // 5 minutes = 300000 ms
            return new Promise((resolve, reject) => {
                const timer = setTimeout(() => {
                    reject(new Error('Timeout: La requête a dépassé le délai de 5 minutes'));
                }, timeout);

                fetch(url, options).then(response => {
                    clearTimeout(timer);
                    resolve(response);
                }).catch(err => {
                    clearTimeout(timer);
                    reject(err);
                });
            });
        };

        try {
            const response = await fetchWithTimeout('https://m67.tech/wp-json/tabac_finisher/v1/respond', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({threadId: threadId, userResponse: content, userSessionId: userSessionId})
            });
            const delay = (duration) => {
                return new Promise(resolve => setTimeout(resolve, duration));
            }



            if (!response.ok) {
                async function fetchDataWithRetries(url, data, retries = 2, delayBeforeRetry = 10000) {
                    for (let i = 0; i <= retries; i++) {
                        try {
                            console.log(`Tentative ${i + 1}: envoi de la requête...`);
                            const response = await fetch(url, {
                                method: 'POST', // Utilisation de la méthode POST pour envoyer des données
                                headers: {
                                    'Content-Type': 'application/json', // Spécification du type de contenu comme JSON
                                },
                                body: JSON.stringify(data), // Conversion des données en chaîne JSON
                            });

                            if (!response.ok) throw new Error(`La requête a échoué avec le statut ${response.status}`);
                            const responseData = await response.json();
                            console.log('Données reçues:', responseData);
                            return responseData; // La requête a réussi, sortie de la fonction
                        } catch (error) {
                            console.log(`Erreur lors de la requête: ${error.message}`);
                            if (i < retries) {
                                console.log(`Attente de ${delayBeforeRetry / 1000} secondes avant la nouvelle tentative...`);
                                await delay(delayBeforeRetry); // Attendre avant de réessayer
                            } else {
                                throw error; // Toutes les tentatives ont échoué, lancer une exception
                            }
                        }
                    }
                }

// Données à envoyer
                const dataToSend = {
                    threadId: thread, // Exemple d'ID de fil
                    userSessionId: userSessionId // Exemple d'ID de session utilisateur
                };
                //TODO géré le code 504
                if (response.status === 504) {
                    toast.error(<ToastLoadingPlan locale={locale}/>);
                    delay(20000).then(
                        () => {
                            fetchDataWithRetries('https://m67.tech/wp-json/tabac_finisher/v1/plan', dataToSend, 2, 10000)
                                .then(data => {
                                        console.log('Succès:', data);

                                        let actualExchange = extractSections(data.answer);//todo mettre a jour en db)
                                        setQuestions(actualExchange);
                                        setAnswerText(actualExchange.texte);
                                    }
                                ).then(() => {
                                    if (plan) {
                                        console.log('change status plan apres 504')
                                        const jsonData = JSON.stringify({
                                            userSessionId: userSessionId,
                                            planStatus: 'generated',
                                            by: 'CLIENT'

                                        })
                                        fetch('https://m67.tech/wp-json/tabac_finisher/v1/planGenerated', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: jsonData // Pas besoin de définir le Content-Type, il est automatiquement défini avec FormData
                                        });

                                    }
                                }
                            )
                                .catch(error => console.error('Échec après plusieurs tentatives:', error));//TODO  géré non reception apres toutes les tentatives

                        }
                    )

                }
                console.log(`La requête a échoué avec le statut ${response.status}`)
                //  throw new Error(`La requête a échoué avec le statut ${response.status}`);
            }

            const data = await response.json();

            let actualExchange = extractSections(data.newAnswer.content[0].text.value);
            //console.log(response)
           // let actualExchange = extractSections(response);
            setQuestions(actualExchange);
            setAnswerText(actualExchange.texte);
            console.log("Réponse traitée avec succès.");

            if (actualExchange.plan.length > 0) {
                //on toast pour avertir que le plan et present et telechargé
                toast.success(<ToastPlan locale={locale}/>, {
                    autoClose: 5000
                });
                const jsonData = JSON.stringify({
                    userSessionId: userSessionId,
                    planStatus: true,
                    by: 'CLIENT'

                })
                //on modifie en base  le status du plan
                fetch('https://m67.tech/wp-json/tabac_finisher/v1/planGenerated', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: jsonData // Pas besoin de définir le Content-Type, il est automatiquement défini avec FormData

                })
            }
        } catch (error) {
            console.error("Erreur lors de la requête:", error);
            // Ici, vous pouvez gérer les erreurs, y compris les erreurs de timeout
        } finally {
            setIsLoading(false);
        }
    };


    function extractSections(text) {
        const exchangeCopy = newExchange(); // Créez une copie de l'objet exchange

        const extractSection = (startTag, endTag, sectionName) => {
            const regex = new RegExp(`\\[${startTag}\\]([\\s\\S]*?)\\[${endTag}\\]`, "g");

            text = text.replace(regex, (_, sectionContent) => {
                if (sectionContent) {
                    exchangeCopy[sectionName].push(sectionContent.trim());
                }
                return "";
            });
        };

        extractSection("question", "question", "questions");
        extractSection("encouragement", "encouragement", "encouragement");
        extractSection("conseil", "conseil", "conseils");
        extractSection("explic", "explic", "questionsDoc");
        extractSection("plan", "plan", "plan");

        exchangeCopy.texte = text.trim();
        let conseils = []
        if (exchangeCopy.conseils.length > 0) {
            exchangeCopy.conseils.forEach((conseil, index) => {
                conseils.push(conseil)
                console.log('conseils: ', conseil)

            });
            addNewItem(locale, conseils);
        }
        let tricks = [];
        if (exchangeCopy.encouragement.length > 0) {
            exchangeCopy.encouragement.forEach((trick, index) => {
                tricks.push(trick)
                console.log('tricks: ', trick)

            });
            addNewTrick(locale, tricks);
        }

        if (exchangeCopy.plan.length > 0) {
            setPlan(true)
        }
        exchanges.table.push(exchangeCopy); // Ajoutez l'exchange extrait à la liste des exchanges

        return exchangeCopy;
    }

    useEffect(() => {
        if (answer) {
            //on verifie si une question type est trouvée sinon si il y a un ? a la fin du texte
            let resptemp = extractSections(answer)

            //cas ou il n'y a pas de question
            if (resptemp.texte.endsWith("?") && resptemp.questions === []) {
                // separer la partie texte et la partie question
                let pointInterrogationIndex = resptemp.texte.indexOf("?");
                let textePart, questionPart;

                if (pointInterrogationIndex !== -1) {
                    textePart = resptemp.texte.substring(0, pointInterrogationIndex).trim(); // La partie avant le point d'interrogation
                    questionPart = resptemp.texte.substring(pointInterrogationIndex + 1).trim(); // La partie après le point d'interrogation
                }
                //mettre la partie texte dans answerText
                setAnswerText(textePart);
                //mettre la partie question dans lastExchange.question
                const lastExchange = exchanges.table[0]
                lastExchange.question = questionPart
                //mettre lastExchange dans question avec setQuestions
                setQuestions(lastExchange)
            }//cas avec question
            else {
                const lastExchange = exchanges.table[exchanges.table.length - 1];
                // Mettez à jour setQuestions avec les questions du dernier échange
                setQuestions(lastExchange);
                console.log('last ex 0 ', lastExchange)
                setAnswerText(lastExchange.text);
            }
        }

    }, [answer]);
    const backgroundColors = ['#a8cef0', '#a8f0d4', '#a8b3f0', '#e7a8f0', '#f0d7a8', '#f0a8b9', '#a8aff0', '#c6f0a8'];
    // Générer une couleur aléatoire
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    const randomColor = backgroundColors[randomIndex];
    const onSubmitAnswers = (answers) => {
        console.log('ANSWER: ', answer)
        setUserAnswers(answers);
        const tes = emitUserResponse(thread, answers)
    };
    const tricky = () => {
        if (tricks.length > 0) {
            console.log('tricky', tricks[tricks.length - 1]);
            return tricks[tricks.length - 1].finalText
        }
    }
    return (
        <div>
            {isLoading ? <Loader astuce={tricky()} color={randomColor}/> : (
                <>
                    <ReactMarkdown>{answerText}</ReactMarkdown>
                    {questions && <QuestionAnswerComponent lastExchange={questions} retryAnswers={retryAnwers}
                                                           onSubmitAnswers={onSubmitAnswers} locale={locale}/>}

                    {plan &&
                        <Plan title={messages[locale].PlanTitle} text={questions.plan} items={items} locale={locale}/>}
                    {plan && locale === 'fr' && <PlanTesterFR text={questions.plan} items={items} tricks={tricks}/>}
                    {plan && locale === 'en' && <PlanTesterEN text={questions.plan} items={items} tricks={tricks}/>}
                    {plan && locale === 'es' && <PlanTesterES text={questions.plan} items={items} tricks={tricks}/>}

                </>
            )}
        </div>
    );
};

export default ReponseComponent;
