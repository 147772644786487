import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Markdown from 'markdown-to-jsx';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from '../logo.jpg'; // Assurez-vous que le chemin vers l'image est correct
import markdownit from 'markdown-it';
import htmlToPdfmake from 'html-to-pdfmake';
import '../css/Plan.css'
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const Plan = ({ title, text, locale, items }) => {
    const componentRef = useRef();

    const messages = {
        en: {

            imprimText: 'Print',
            downloadLabel:  'PDF download'
        },
        fr: {
            imprimText: 'Imprimer',
            downloadLabel:  'Télécharger le PDF'
        },
        es: {
            imprimText: 'Imprimir',
            downloadLabel:  'Descargar el PDF'
        }
    };

    const handleDownloadPdf = async () => {
        const md = new markdownit();
        const htmlText = md.render(text[0]);
        const pdfContent = htmlToPdfmake(htmlText);
        const backgroundColors = ['#f0f8ff', '#faebd7', '#7fffd4', '#f0ffff', '#f5f5dc']; // Ajoutez plus de couleurs si nécessaire

        const cardContents = items.map((item, index) => {
            const itemHtmlText = md.render(item.finalText);
            const backgroundColor = backgroundColors[index % backgroundColors.length]; // Choisissez une couleur du tableau

            // Créer un conteneur avec la couleur de fond
            return {
                table: {
                    body: [
                        [{ text: htmlToPdfmake(itemHtmlText), fillColor: backgroundColor }]
                    ]
                },
                layout: 'noBorders' // Supprimer les bordures de la table
            };
        });

        const documentDefinition = {
            content: [
                { text: title, fontSize: 26, bold: true, margin: [0, 0, 0, 10] },
                ...pdfContent, // Contenu du texte Markdown original
                ...cardContents, // Contenu des cartes ajouté ici
            ],
            styles: {
                header: { fontSize: 22, bold: true },
            },
            defaultStyle: { fontSize: 12 },
        };

        pdfMake.createPdf(documentDefinition).download('plan.pdf');
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
        <div ref={componentRef}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src='https://m67.tech/static/media/logo.1318206de1b27cb1a08f.jpg'  alt="Logo" style={{ width: '250px', height: '250px' }} />
                <h2>{title}</h2>
            </div>

            <Markdown>{text[0]}</Markdown>
        </div>
            <div>


        </div>
    </div>
    );
}

export default Plan;
