const pdfElementsES = {
    title1: "Tu plan personalizado",
    firstSentence:
        "Dejar de fumar es un desafío, pero no imposible. Con motivación y fuerza de voluntad podrás lograrlo.",
    conseilTitle: 'Nuestro consejo para conseguir tu objetivo:',
    conseilSentence:
        "El viaje se ajusta en función de tus respuestas y está estructurado para apoyarte en cada paso del camino con objetivos alcanzables y recompensas para aumentar tu motivación.",
    astucesTitle: "Truco",
    astucesSentence: "No dudes en leer las siguientes frases con regularidad para mantener tu motivación.",
};

export default pdfElementsES;