import React from 'react';
import '../css/ToastStripeOK.css'
const ToastWarningBack= ({locale , date})=>{
    const messages = {
        en: {

            text:"Plan already generated on: ",
            text3:"\nIf you have not received your plan (download problem,...) please contact our support service.",
        },
        fr: {
            text: "Plan a djà été généré le :",
            text3: "\nSi vous n'avez pas eu votre plan (problème de téléchargement,...) merci de contacter notre service support."
        },
        es: {
            text: 'Plan sido generado en: ',
            text3:"\nSi no ha recibido su plan (problema de descarga,...), comuníquese con nuestro servicio de soporte.",

        }
    };
    return ( <div className='toast_strip_ok_container'>
        <div className='toast_strip_ok_image'>
            <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' width="50px" height="50px"/>
        </div>
        <div >

            <div className='toast_strip_ok_text'>
                {messages['es'].text}{date}<br/>
                {messages['es'].text3}<br/>
                {messages['fr'].text}{date}<br/>
                {messages['fr'].text3}<br/>
                {messages['en'].text}{date}<br/>
                {messages['en'].text3}<br/>
            </div>
        </div>
    </div>)

}
export default ToastWarningBack;