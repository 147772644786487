
import React from 'react';
import '../css/QuestionTester.css';
const QuestionTester = ({question,number,addToRefs})=>{
    const backgroundColor = `hsl(${number*9 % 360}, 70%, 80%)`;

    return <>
        <div className='questionElement' style={{ backgroundColor}}>
            <div className='QuestionNumberContainer'>
                <div className='questionNumber'>{number+1}</div>

            </div>
            <div className='questioncontainer'>
                <div className='questionText'>{question}</div>

                <div className='responseText'>
                    <textarea ref={addToRefs} id='language' className='questionInput' type='text'/>
                </div>
            </div>
        </div>
    </>
}
export default QuestionTester;