const pdfElementsFR = {
    title1: "Votre plan personnalisé",
    firstSentence:
        "Arrêter de fumer est un défi, mais ce n'est pas impossible. Avec de la motivation et de la volonté, vous pouvez y arriver.",
    conseilTitle: 'Nos conseil pour atteindre votre objectif :',
    conseilSentence:
        "Le parcours s’ajuste en fonction de vos réponses et est structuré pour vous soutenir à chaque étape avec des objectifs réalisables et des récompenses pour renforcer votre motivation.",
    astucesTitle: "Astuces",
    astucesSentence: "N'hésitez pas à lire régulièrement les phrases qui suivent pour conserver votre motivation.",






    bonusTitle1: "Comment le corps s'améliore-t-il en arrêtant de fumer ?",
    bonusSentence:
        "Le tabac est l'une des principales causes de décès évitables dans le monde. Fumer des cigarettes augmente le risque de cancer, de maladies cardiaques, d'accidents vasculaires cérébraux, de maladies respiratoires chroniques et d'autres affections graves. Lorsqu'on arrête de fumer, le corps commence à se remettre des dommages causés par le tabac. Les bienfaits de l'arrêt du tabac commencent immédiatement et continuent de s'améliorer avec le temps.\n",
    bonusTitle2: "Bénéfices immédiats",
    bonusSentence2:
        "Dans les 24 premières heures, les niveaux de monoxyde de carbone dans le sang diminuent de moitié. Dans les 2 premières semaines, la circulation sanguine s'améliore et la toux diminue. Dans les 8 premières semaines, la fonction pulmonaire s'améliore.\n",
    bonusTitle3: "Bénéfices à long terme",
    bonusSentence3:
        "Dans les 5 ans suivant l'arrêt du tabac, le risque de décès par crise cardiaque est divisé par deux. Dans les 10 ans suivant l'arrêt du tabac, le risque de décès par cancer du poumon est divisé par deux. Dans les 15 ans suivant l'arrêt du tabac, le risque de décès par maladie cardiaque est le même que celui d'une personne qui n'a jamais fumé.",
    bonusTitle5: "Effets spécifiques du tabac sur l'organisme",

    bonus4_content:
        "Le tabac endommage les poumons et les voies respiratoires, ce qui peut entraîner une série de problèmes respiratoires, tels que l'asthme, la bronchite chronique et la bronchopneumopathie chronique obstructive (BPCO). Après l'arrêt du tabac, les poumons commencent à se guérir. La toux et l'expectoration diminuent, la respiration devient plus facile et la capacité pulmonaire augmente.\n",
    bonus4_1_title: "Premières 24 heures\n",
    bonus4_1_1: "- Les niveaux de monoxyde de carbone dans le sang diminuent de moitié.",
    bonus4_1_2: "- La circulation sanguine s'améliore.",
    bonus4_1_3: "- La toux diminue.\n",
    bonus4_2_title: "Semaines 2-8\n",
    bonus4_2_1: "- La fonction pulmonaire s'améliore.",
    bonus4_2_2: "- La capacité pulmonaire augmente.",
    bonus4_3_title: "Mois 1-3\n",
    bonus4_3_1: "- La respiration devient plus facile.",
    bonusTitle4: "Comment le corps s'améliore après l'arrêt du tabac",

    bonus5_1_title: "Système respiratoire\n",
    bonus5_1_1:
        "Le tabac endommage les poumons et les voies respiratoires, ce qui peut entraîner une série de problèmes respiratoires, tels que l'asthme, la bronchite chronique et la bronchopneumopathie chronique obstructive (BPCO). Après l'arrêt du tabac, les poumons commencent à guérir. La toux et l'expectoration diminuent, la respiration devient plus facile et la capacité pulmonaire augmente.\n",
    bonus5_2_title: "Système cardiovasculaire\n",
    bonus5_2_1:
        "Le tabac rétrécit les artères et augmente le risque de maladie cardiaque, d'accident vasculaire cérébral et d'autres affections cardiovasculaires. Après l'arrêt du tabac, les artères commencent à se détendre et la pression artérielle diminue. Le risque de maladie cardiaque, d'accident vasculaire cérébral et d'autres affections cardiovasculaires diminue.\n",
    bonus5_3_title: "Système digestif\n",
    bonus5_3_1:
        "Le tabac peut endommager l'estomac et l'œsophage, ce qui peut augmenter le risque de cancer de l'estomac et de l'œsophage. Après l'arrêt du tabac, le risque de cancer de l'estomac et de l'œsophage diminue. Système reproducteur Le tabac peut endommager les spermatozoïdes et les ovules, ce qui peut rendre la grossesse plus difficile et augmenter le risque de fausse couche. Après l'arrêt du tabac, la fertilité s'améliore.\n",
    bonus5_4_title: "Peau\n",
    bonus5_4_1:
        "Le tabac peut accélérer le vieillissement de la peau et augmenter le risque de rides et de taches. Après l'arrêt du tabac, la peau commence à se régénérer.\n",
    bonus5_5_title: "Os\n",
    bonus5_5_1:
        "Le tabac peut réduire la densité osseuse, ce qui peut augmenter le risque d'ostéoporose et de fractures. Après l'arrêt du tabac, la densité osseuse augmente.\n",
    bonus5_6_title: "Dents\n",
    bonus5_6_1:
        "Le tabac peut causer une série de problèmes dentaires, tels que des caries, des gencives saignantes et la perte de dents. Après l'arrêt du tabac, les problèmes dentaires peuvent s'améliorer.\n",
    bonus5_7_title: "Dépression et anxiété",
    bonus5_7_1: "Fumer peut augmenter le risque de dépression et d'anxiété. Après l'arrêt du tabac, le risque de dépression et d'anxiété diminue.\n",
    bonus5_8_title: "Cancer\n",
    bonus5_8_1:
        "Le tabac est la principale cause de cancer dans le monde. Fumer augmente le risque de cancer du poumon, de la gorge, du pharynx, de l'œsophage, de l'estomac, du pancréas, du rein, de la vessie, du col de l'utérus et de la leucémie. Après l'arrêt du tabac, le risque de cancer diminue.\n",
    bonus5_9_title: "Conclusion\n",
    bonus5_9_1:
        "Arrêter de fumer est l'une des meilleures choses que vous puissiez faire pour votre santé. Les bienfaits de l'arrêt du tabac commencent immédiatement et continuent de s'améliorer au fil du temps. Si vous fumez, arrêtez de fumer dès aujourd'hui.\n"
};

export default pdfElementsFR;
