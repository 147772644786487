import React from 'react';
import '../css/QuestionTester.css';
const QuestionLanguage = ({number,locale, getQuestions, setLanguage})=>{
    const messages = {
        en: {

            buttonText: 'Answer',
            questionLabel: 'What language do you want to speak in?'
        },
        fr: {
            buttonText: 'Répondre',
            questionLabel: 'Dans quelle langue souhaitez-vous parlez?'
        },
        es: {
            buttonText:'Pregunta',
            questionLabel:  '¿En qué idioma quieres hablar?'
        }
    };
    const handle_repondre_click=()=>{

        let language = document.getElementById("language").value;
        setLanguage(language);
        const jsonData = JSON.stringify({
            language: language,
        })
        getQuestions(jsonData);

    }
    const backgroundColor = `#11142c`;

    return <>
        <div className='questionElement' style={{ backgroundColor}}>
            <div className='QuestionNumberContainer'>
                <div className='questionNumber'>?</div>

            </div>
            <div className='questioncontainer'>
              <div className='questionText'>{messages[locale].questionLabel}</div>

                <div className='responseText'>
                    <input id='language' className='questionInput' type='text'/>
                </div>
                <button className='button_testModal' onClick={handle_repondre_click}>{messages[locale].buttonText}</button>
            </div>
        </div>
    </>

}
export default QuestionLanguage;