const pdfElementsEN = {
    title1: "Your personalized plan",
    firstSentence:
        "Quitting smoking is a challenge, but it's not impossible. With motivation and willpower, you can do it.",
    conseilTitle: 'Our advice to achieve your goal:',
    conseilSentence:
        "The journey adjusts based on your responses and is structured to support you every step of the way with achievable goals and rewards to boost your motivation.",
    astucesTitle: "Tricks",
    astucesSentence: "Do not hesitate to read the following sentences regularly to maintain your motivation.",
};

export default pdfElementsEN;