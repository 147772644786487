import React from 'react';
import '../css/ToastStripeOK.css'
const ToastStripeOK= ({locale})=>{
    const messages = {
        en: {
            title: 'Thank you, Your payment has been accepted.',
            text: "An email containing a recovery link has been sent to you.\n" +
                "This link remains valid until your personalized plan has been generated."
        },
        fr: {
            title: 'Merci,Votre paiement à bien été accepté.',
            text: "Un mail contenant un lien de récupération vous a été envoyé.\nCe lien reste valable jusqu'a ce que votre plan personnalisé ait été généré."
        },
        es: {
            title: 'Gracias, su pago ha sido aceptado.',
            text: 'Se le ha enviado un correo electrónico que contiene un enlace de recuperación.\n' +
                'Este enlace sigue siendo válido hasta que se haya generado tu plan personalizado.'
        }
    };
    return ( <div className='toast_strip_ok_container'>
            <div className='toast_strip_ok_image'>
                <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' width="50px" height="50px"/>
            </div>
        <div className='content-container'>
            <div className='toast_strip_ok_title'>
                {messages[locale].title}
            </div>
            <div className='toast_strip_ok_text'>
                {messages[locale].text}
            </div>
        </div>
        </div>)

}
export default ToastStripeOK;