import React, { useState,useEffect, useRef } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import '../css/LanguageSelector.css';
const LanguageSelector = ({setLocale,locale}) => {
    const selectRef = useRef(null); // Ref pour accéder au select
    const messages = {
        en: {
            language: 'Interface language'
        },
        fr: {
            language: 'Langue de l\'interface'
        },
        es: {
            language: 'Lenguaje de interfaz'
        }
    };
    useEffect(() => {
        const adjustSelectWidth = () => {
            if (selectRef.current) {
                let maxWidth = 0;
                const options = selectRef.current.options;

                for (let i = 0; i < options.length; i++) {
                    // Créer un élément span temporaire pour mesurer la longueur du texte
                    const span = document.createElement('span');
                    span.innerHTML = options[i].text;
                    document.body.appendChild(span);
                    maxWidth = Math.max(maxWidth, span.offsetWidth);
                    document.body.removeChild(span); // Nettoyer l'élément span temporaire
                }

                selectRef.current.style.width = `${maxWidth + 30}px`; // Ajouter un peu de marge
            }
        };

        adjustSelectWidth();
    }, []);
    return (
        <div className="selectbox-container">
            <span className='lang_interface_label'> {messages[locale].language}</span>

                <div className='language'>
                    <img className='' src="/static/media/EN.png" width='10%' onClick={()=>{setLocale('en')}} alt="Info"
                         style={{cursor: 'pointer'}}/>
                    <img className='' src="/static/media/FR.png" width='10%' onClick={()=>{setLocale('fr')}} alt="Info"
                         style={{cursor: 'pointer'}}/>
                    <img className='' src="/static/media/ES.png" width='10%' onClick={()=>{setLocale('es')}} alt="Info"
                         style={{cursor: 'pointer'}}/>
                </div>

        </div>
    );
};
export  default LanguageSelector;