import React from 'react';
import '../css/Loader.css';
import logoChargement from '../assets/logo_chargement.png'; // Assurez-vous que le chemin est correct

function Loader({ astuce, color = "#A8F0D4" }) {
    const containerStyle = astuce ?
        { backgroundColor: color } :
        { backgroundColor: color, height: '12%' };

    return (
        <div className="loader-overlay">
            <div className="loader-container" style={containerStyle}>

                <div className="loader-text">En carga / Loading / Chargement</div>
                {astuce && <div className='astuce'>{astuce}</div>}
            </div>
        </div>
    );
}
export default Loader;
