import React from 'react';
import '../css/ToastStripeOK.css'
const ToastWarningBack= ({locale})=>{
    const messages = {
        en: {
            title: 'Do not backtrack during the questionnaire.',
            text:"If you have a problem, please refer to the email you received."
        },
        fr: {
            title: 'Ne faites pas de retour en arrière durant le questionnaire.',
            text: "En cas de problème merci de vous référer à l'email que vous avez reçu"
        },
        es: {
            title: 'No retroceda durante el cuestionario..',
            text: 'Si tiene algún problema, consulte el correo electrónico que recibió.'

        }
    };
    return ( <div className='toast_strip_ok_container'>
        <div className='toast_strip_ok_image'>
            <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' width="50px" height="50px"/>
        </div>
        <div className='content-container'>
            <div className='toast_strip_ok_title'>
                {messages[locale].title}
            </div>
            <div className='toast_strip_ok_text'>
                {messages[locale].text}
            </div>
        </div>
    </div>)

}
export default ToastWarningBack;