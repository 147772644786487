import React, {useRef, useEffect, useState} from 'react';
import Modal from 'react-modal';
import '../css/InfoModal.css'
import '../css/TestModal.css'
import QuestionTester from './QuestionTester';
import QuestionLanguage from "./QuestionLanguage";
/* global tabac_finisherNonce */
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.75)';

const TestModal = ({locale = 'es'}) => {
    const messages = {
        en: {
            buttonLogin: 'Connection',
            buttonRegister: 'Registration',
            notConnected: 'Please log in or register before starting the test.',
            buttonText: 'Send',
            chargement: 'We will translate your questions, please be patient, we will do so as quickly as possible'
        },
        fr: {
            buttonLogin: 'Connexion',
            buttonRegister: 'Inscription',
            notConnected: 'Merci de vous connecter ou de vous inscrire avant de débuter le test. ',
            buttonText: 'Envoyer',
            chargement: 'Nous traduisons vos questions, merci de patienter nous faisons au plus vite'
        },
        es: {
            buttonLogin: 'Conexión',
            buttonRegister: 'Registro',

            notConnected: 'Por favor inicie sesión o regístrese antes de comenzar la prueba.',
            buttonText: 'Enviar',
            chargement: 'Traduciremos tus preguntas, ten paciencia, lo haremos lo más rápido posible.'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [language, setLanguage] = useState(null)
    const [questions, setQuestions] = useState(null)
    const [isUserConnected, setIsUserConnected] = useState(false)
    const [userMail, setUserMail] =useState(null)
    const inputsRef = useRef([]);
    const addToRefs = el => {
        console.log(el)
        if (el && !inputsRef.current.includes(el)) {
            inputsRef.current.push(el);
        }
    };
    const soumettre = () => {
        console.log(inputsRef)
        // Accéder aux valeurs en parcourant le tableau de références
        const valeurs = inputsRef.current
            .filter(input => input.value !== '') // Filtrer les inputs vides
            .map(input => input.value); // Extraire les valeurs
        let textResponse = valeurs.join(',');
        const testResponse = JSON.stringify({
            textResponse: textResponse,
            userEmail: userMail
        })
        console.log(textResponse)
        fetch('https://m67.tech/wp-json/tabac_finisher/v1/addict', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-WP-Nonce': tabac_finisherNonce.nonce, // Utilisez le nonce ici
                'Content-Type': 'application/json'
            },
            body: testResponse // Pas besoin de définir le Content-Type, il est automatiquement défini avec FormData
        }).then(response => {
            console.log(response)
        })
        ;
    };
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const getQuestions = (jsonData) => {
        fetch('https://m67.tech/wp-json/tabac_finisher/v1/traduction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonData
        })
            .then(response => response.json()) // Convertir la réponse en JSON
            .then(data => {
                // Utilisez setQuestions pour mettre à jour l'état avec les données reçues
                setQuestions(data);
                let regex = /\{.*\}/s;
                let match = data.match(regex);
                if (match) {
                    let jsonStr = match[0];

                    // Essayer de parser la chaîne JSON extraite
                    try {
                        let questionArray = JSON.parse(jsonStr);
                        console.log("Données extraites et parsées:", questionArray);
                        setQuestions(Object.values(questionArray));
                        console.log(Object.values(questionArray))
                        console.log(questionArray["5"])
                        closeModal();

                    } catch (error) {
                        console.error("Erreur lors du parsing JSON:", error);
                    }
                } else {
                    console.error("Aucun contenu JSON valide trouvé dans la chaîne.");
                }


            })
    }
    const redirLogin = () => {
        window.location.href = '/login';
    }
    const redirRegister = () => {
        window.location.href = '/register';
    }
    useEffect(() => {
        fetch('https://m67.tech/wp-json/wp/v2/users/me', {
            credentials: 'include',
            headers: {
                'X-WP-Nonce': tabac_finisherNonce.nonce, // Utilisez le nonce ici
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 200) {
                    console.log("L'utilisateur est connecté.");
                    fetch('https://m67.tech/wp-json/tabac_finisher/v1/current-user', {
                        credentials: 'include',// Important pour les cookies de session
                        headers: {
                            'X-WP-Nonce': tabac_finisherNonce.nonce, // Utilisez le nonce ici
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json()) // Convertissez la réponse en JSON
                        .then(data => {
                            if (data.ID) { // Assurez-vous que data.ID existe
                                setIsUserConnected(true);
                                console.log('response:', data); // Affiche les données de l'utilisateur
                                setUserMail(data.user_email); // Utilisez data.user_email pour accéder à l'email de l'utilisateur
                                console.log("L'utilisateur est identifié.");
                            } else {
                                console.log("L'utilisateur n'est pas identifié.");
                            }
                        })
                        .catch(error => console.error('Erreur:', error));

                } else {
                    setIsUserConnected(false)
                    console.log("L'utilisateur n'est pas connecté.");
                }
            })
            .catch(error => console.error('Erreur :', error));


    }, []);
    useEffect(() => {

        console.log(questions);
        if (questions) openModal();
    }, [questions]);
    return (
        <div>
            <img className='img_info' src="/static/media/info.png" onClick={openModal} alt="Info"
                 style={{cursor: 'pointer'}}/>

            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Info Modal"
                className="modal-contentp" // Ajout de la classe pour les styles
            >
                {!isUserConnected &&
                    <div >
                        <div className='notConnectedText'>
                            {messages[locale].notConnected}
                        </div>
                    <div className='notRegistredcontainer'>
                    <button className='button_login' onClick={redirLogin}>{messages[locale].buttonLogin}</button>

                    <button className='button_register'
                            onClick={redirRegister}>{messages[locale].buttonRegister}</button>
                    </div>
                    </div>
                }
                {!language && isUserConnected &&
                    <QuestionLanguage question={'language'} locale={locale} getQuestions={getQuestions}
                                      setLanguage={setLanguage} number={0}/>
                }
                {language && !questions && isUserConnected &&
                    <div> {messages[locale].chargement}</div>
                }
                {questions && isUserConnected && questions.map((question, index) => (
                    <QuestionTester key={index} addToRefs={addToRefs} question={question} number={index}/>)
                )}
                {questions && isUserConnected &&
                    <button className='button_sendModal' onClick={soumettre}>{messages[locale].buttonText}</button>
                }


            </Modal>
        </div>
    );
};

export default TestModal;
