import {useState, useEffect} from 'react';
import './App.css';
import Talker from "./components/talker";
import ResponseComponent from "./components/ResponseComponent";
import CardContainer from './components/CardContainer';
import LanguageSelector from './components/LanguageSelector';
import {v4 as uuidv4} from 'uuid';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastStripeOK from "./components/ToastStripeOK";
import ToastWarningBack from "./components/ToastWarningBack";
import InfoModal from "./components/InfoModal";
import Carousel from "./components/Caroussel";
import ToastPlanGenerated from "./components/ToastPlanGenerated";
import TestModal from "./components/TestModal";
import Dial from "./components/Dial"
function App() {
    const [lastAnswer, setLastAnswer] = useState([]);
    const [thread, setThread] = useState(null);
    const [items, setItems] = useState([]);
    const [tricks, setTricks] = useState([]);
    const [locale, setLocale] = useState('es');
    const [paymentVerified, setPaymentVerified] = useState(false);
    const [showPaymentButton, setShowPaymentButton] = useState(true);
    const [userSessionId, setUserSessionId] = useState(null);
    const [planGenerated, setPlanGenerated] = useState(false);


    const messages = {
        en: {title: 'Advice'},
        fr: {title: 'conseil'},
        es: {title: 'Consejo'}
    };
    const astucesLabel = {
        en: {title: 'Tricks'},
        fr: {title: 'Astuces'},
        es: {title: 'Trucos'},
    }

    // Chargement du script Stripe
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    //verification si le paiement a bien été effectué
    useEffect(() => {
        // Exécutez cette logique après la redirection réussie de Stripe
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        setUserSessionId(sessionId)
        console.log('session: ', sessionId)
        //    console.log('transact_id: ', transactionId)
        if (sessionId) {
            // Envoyer cet ID au backend pour vérifier le paiement
            verifyPayment(sessionId);
        }
    }, []);

    const verifyPayment = async (sessionId, transactionId) => {
        try {
            const response = await fetch(`/wp-json/tabac_finisher/v1/verify-payment?session_id=${sessionId}`);
            const data = await response.json();
            //si le paiement est ok et que le plan n'a pas été généré
            if (data.paymentVerified === 'completed' && data.planGenerated !== 'generated') {
                setPaymentVerified(true);
                setShowPaymentButton(false);
                toast.success(<ToastStripeOK locale={locale}/>, {
                    autoClose: 4000
                })
                toast.warning(<ToastWarningBack locale={locale}/>, {
                    autoClose: 6000
                })
            } else if (data.paymentVerified === 'completed' && data.planGenerated === 'generated') {
                setShowPaymentButton(true);
                //todo add toast pour informer que le plan a déja été généré et que pour en avoir un nouveau il faut payer a nouveau  ou ce rapprocher du support si contestation
                toast.warning(<ToastPlanGenerated locale={locale} date={data.planDate}/>, {
                    autoClose: 10000
                })
                console.log('plan generé')
            }

            // Vous pouvez également traiter d'autres actions après la vérification du paiement
            else if (data.paymentVerified !== 'completed') {
                //TODO: Gérer le cas où le paiement n'est pas vérifié
                console.log('paiement NON verifier')
            }
        } catch (error) {
            console.error('Erreur lors de la vérification du paiement', error);
            // Gérer les erreurs de la requête ici
        }
    };


    const handlePaymentButtonClick = () => {
        console.log('handlepaiementclick')
        const transactionId = uuidv4(); // Générer l'UUID
        localStorage.setItem('transactionId', transactionId); // Stocker l'UUID
        console.log('transacId', transactionId)

    };


    const stripePaymentButton = (
        <stripe-buy-button

            buy-button-id="buy_btn_1OYPbJGUxlt7jCt1TDdUHh6V"
            publishable-key="pk_live_51ORzNnGUxlt7jCt1zSJzxaQ7SW0h2LtXARYJQ7HWS1i2NnoKZZjDb3s3nb8i5daMMX7MVM9Y5OYhsEB4h7axfirr00cXlU31Us"
        ></stripe-buy-button>
    );
//ajout des conseil dans le tableau
    const addNewItem = (locale, text) => {
        let finalText = text.reduce((acc, texte, index) =>
            `${acc}${messages[locale].title} N°${index + 1}: ${texte}\n`, '');
        const newItem = {
            title: messages[locale].title,
            finalText,
            id: Date.now()
        };
        setItems([...items, newItem]);
    };

    //ajout des astuces dans le tableau
    const addNewTrick = (locale, text) => {
        let finalText = text.reduce((acc, texte, index) =>
            `${acc}${astucesLabel[locale].title} N°${index + 1}: ${texte}\n`, '');
        const newTrick = {
            title: messages[locale].title,
            finalText,
            id: Date.now()
        };
        setTricks([...tricks, newTrick]);
    };


    return (
        <div className="App">
            <header className="App-header">
                <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' className="App-logo" alt="logo"/>
                <div className="title-container">

                    <Dial percentage={80}/>
                    <div className='app_title_info'>
                        <span><TestModal locale={locale}/></span>
                        <span><InfoModal location={locale}/></span>
                        <span><h3 className="title_app">Tabac Finisher</h3></span>

                    </div>

                    {!paymentVerified && <Carousel/>}


                </div>
                <ToastContainer position="top-center" width="40"/>
                {showPaymentButton && stripePaymentButton}
                {showPaymentButton && <img src='/static/media/prix discount2.png' className="img_discount"/>}
                {paymentVerified && !planGenerated && (
                    <div className='app-content'>
                        <div><LanguageSelector setLocale={setLocale} locale={locale}></LanguageSelector></div>
                        {locale && <div className="App"><CardContainer items={items}/></div>}
                        {lastAnswer.length === 0 && locale &&
                            <Talker userSessionId={userSessionId} setLastAnswer={setLastAnswer} setThread={setThread} locale={locale}/>}
                        <ResponseComponent userSessionId={userSessionId} answer={lastAnswer.answer} thread={thread}
                                           addNewTrick={addNewTrick} tricks={tricks} addNewItem={addNewItem}
                                           items={items} locale={locale}/>
                    </div>
                )}
                <span className='copyright'> &copy; Powered by M67</span>
            </header>
        </div>
    );
}

export default App;
