// exchange.js

const exchange = {
    questions: [],
    questionsDoc: [],
    conseils: [],
    encouragement: [],
    texte: "",
    plan: []
};

export default exchange;
