import React from 'react';
import '../css/ToastStripeOK.css'
const Toastplan = ({locale})=>{
    const messages = {
        en: {
            title: 'Your personalized plan has been downloaded - file "plan.pdf."',
            text:"Find your plan, your advice, your tips, as well as a bonus in the downloaded pdf."
        },
        fr: {
            title: 'Votre plan personnalisé à été téléchargé - fichier "plan.pdf".',
            text: "Retrouvez votre plan, vos conseils, vos astuces, ainsi qu'un bonus dans le pdf téléchargé."
        },
        es: {
            title: '\n' +
                'Su plan personalizado ha sido descargado - archivo "plan.pdf".',
            text: 'Encuentra tu plan, tus consejos, tus tips, además de un bono en el pdf descargado.'

        }
    };
    return ( <div className='toast_strip_ok_container'>
        <div className='toast_strip_ok_image'>
            <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' width="50px" height="50px"/>
        </div>
        <div className='content-container'>
            <div className='toast_strip_ok_title'>
                {messages[locale].title}
            </div>
            <div className='toast_strip_ok_text'>
                {messages[locale].text}
            </div>
        </div>
    </div>)

}
export default Toastplan;