import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/Caroussel.css'


Modal.setAppElement('#root');

const Carousel = ({ images }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState();
    const [locale, setLocale] = useState('es')

    const messages = {
        en: {
            btnClose:'close'

        },
        fr: {
            btnClose:'fermer'
        },
        es: {
            btnClose:'Cerrar'
        }
    };


    const settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        //centerMode: true,
        focusOnSelect: true,


    };

    const openModal = (img) => {
        setCurrentImage(img);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
            width: '900px', // ou la taille que vous voulez
            height: '900px' // ou la taille que vous voulez
        }
    };
    return (
<>
            <div className='language_carousel'>
                <img className='' src="/static/media/EN.png" width='10%' onClick={()=>{setLocale('en')}} alt="Info"
                     style={{cursor: 'pointer'}}/>
                <img className='' src="/static/media/FR.png" width='10%' onClick={()=>{setLocale('fr')}} alt="Info"
                     style={{cursor: 'pointer'}}/>
                <img className='' src="/static/media/ES.png" width='10%' onClick={()=>{setLocale('es')}} alt="Info"
                     style={{cursor: 'pointer'}}/>
            </div>
        <div className='carousel-cont'>
            <Slider className='slider_class' {...settings} >


                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/planFR.png'  width='558px' onClick={() => openModal('/static/media/planFR.png')}/>}
                    {locale==='es' && <img src='/static/media/planES.png'  width='558px' onClick={() => openModal('/static/media/planES.png')}/>}
                    {locale==='en' && <img src='/static/media/planEN.png'  width='558px' onClick={() => openModal('/static/media/planEN.png')}/>}
                </div>
                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/questionFR.png' width='558px' onClick={() => openModal('/static/media/questionFR.png')}/>}
                    {locale==='es' && <img src='/static/media/questionES.png' width='558px' onClick={() => openModal('/static/media/questionES.png')}/>}
                    {locale==='en' && <img src='/static/media/questionEN.png' width='558px' onClick={() => openModal('/static/media/questionEN.png')}/>}
                </div>
                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/conseilFR.png' width='558px' onClick={() => openModal('/static/media/conseilFR.png')} />}
                    {locale==='es' && <img src='/static/media/conseilES.png' width='558px' onClick={() => openModal('/static/media/conseilES.png')}/>}
                    {locale==='en' && <img src='/static/media/conseilFR.png' width='558px' onClick={() => openModal('/static/media/conseilFR.png')}/>}

                </div>
                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/astucesFR.png' width='558px' onClick={() => openModal('/static/media/astucesFR.png')} />}
                    {locale==='es' && <img src='/static/media/astucesES.png' width='558px' onClick={() => openModal('/static/media/astucesES.png')}/>}
                    {locale==='en' && <img src='/static/media/astucesFR.png' width='558px' onClick={() => openModal('/static/media/astucesFR.png')}/>}

                </div>
                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/constitutionFR.png' width='558px' onClick={() => openModal('/static/media/constitutionFR.png')} />}
                    {locale==='es' && <img src='/static/media/constitutionES.png' width='558px' onClick={() => openModal('/static/media/constitutionES.png')}/>}
                    {locale==='en' && <img src='/static/media/constitutionEN.png' width='558px' onClick={() => openModal('/static/media/constitutionEN.png')}/>}

                </div>
                <div  style="text-align: center;" >
                    {locale==='fr' && <img src='/static/media/etablissementFR.png' width='558px' onClick={() => openModal('/static/media/etablissementFR.png')} />}
                    {locale==='es' && <img src='/static/media/etablissementES.png' width='558px' onClick={() => openModal('/static/media/etablissementES.png')}/>}
                    {locale==='en' && <img src='/static/media/etablissementEN.png' width='558px' onClick={() => openModal('/static/media/etablissementEN.png')}/>}

                </div>
            </Slider>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
              className='modal-contentp'
            >
                <div className='image_modale'>
                <img src={currentImage} className='image_modale' alt="Expanded" width="90%"height='90%' />
                </div>
            <div className='btn_cl'>
                <button className='btn_bg ' onClick={closeModal}>{messages[locale].btnClose}</button>
            </div>
            </Modal>
        </div>
</>
    );
};

export default Carousel;