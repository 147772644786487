import React, {useState} from 'react';
import Loader from './Loader';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../css/talker.css"
import {IntlProvider, FormattedMessage} from 'react-intl';

const messages = {
    en: {
        buttonText: 'Ask me',
        languageTospeek: 'Our exchange will only take place in english.',
        placeholder :'Indicate in which language you wish to continue, then express your wish. The questions and answers will be in the language you indicate.\n' +
            ' (Example: “I want to speak English. I would like to stop in 3 months).'
    },
    fr: {
        buttonText: 'Demandez moi',
        languageTospeek: 'Notre échange se fera uniquement en français.',
        placeholder: 'Indiquez dans quelle langue vous souhaitez poursuivre, puis exprimez votre souhait. Les questions les réponses vous seront dans la langue que vous aurez indiquée.\n (Exemple : "je veux parler en français. Je voudrais arrêter en 3 mois)'
    },
    es: {
        buttonText: 'Pregúntame',
        languageTospeek: 'Indique en qué idioma desea continuar y luego exprese su deseo. Las preguntas y respuestas estarán en el idioma que indiques.\n' +
            ' (Ejemplo: “Quiero hablar en español. Me gustaría dejar de hablar en 3 meses).',
        placeholder :'Escribe en tu idioma, las preguntas y respuestas estarán en tu idioma.'
    }
};

function Talker({userSessionId,setLastAnswer, setThread, locale}) {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async () => {
        setIsLoading(true);
        console.log('handle sub', messages[locale].languageTospeek);
        //setQuestion(messages[locale].languageTospeek + question);

        // Créer une promesse qui sera rejetée après un timeout
        const timeoutPromise = new Promise((resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(new Error('La requête a dépassé le temps limite'));
            }, 150000); // 150 secondes ou 2.5 minutes
        });

        try {
            // Utiliser Promise.race pour lancer la requête et le timeout en parallèle
            const response = await Promise.race([
                fetch('https://m67.tech/wp-json/tabac_finisher/v1/ask', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ question: question, userSessionId:userSessionId }),
                }),
                timeoutPromise
            ]);

            if (!response.ok) {
                throw new Error('La réponse du réseau n\'était pas ok');
            }

            const data = await response.json();
            console.log(data.toString())
            setAnswer(data.answer);
            console.log('threadID talker:', data.answer.threadId);
            setThread(data.answer.threadId);
            setLastAnswer(data.answer);
        } catch (error) {
            console.error('Erreur lors de la récupération:', error);
            // Gérer l'erreur ici (par exemple, en définissant un état d'erreur)
        } finally {
            setIsLoading(false);
        }
    };
const color ='#A8F0D4';
    return (
        <div>
            {isLoading ? <Loader color={color}/> : (
                <div className="input-button-container">
                    <Form className='input-text-container'>
                        <Form.Control className="input-text-style" as="textarea" rows={6}
                                      onChange={(e) => setQuestion(e.target.value)} value={question} placeholder={messages[locale].placeholder}/>
                    </Form>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                        <div>
                            <button className="button-style" onClick={handleSubmit}>
                                <FormattedMessage id="buttonText" defaultMessage="Click Me"/>
                            </button>
                        </div>
                    </IntlProvider>
                </div>
            )}
        </div>
    );
};

export default Talker;
